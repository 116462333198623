import { call, put } from "redux-saga/effects"
import { SET_PRESS } from "../actions"
import firebase from 'firebase/app';

function connect() {
    return new Promise(resolve => {
        const database = firebase.database();
        const connectionRef = database.ref('press');
        connectionRef.on('value', resolve);
    });
}
export function* fetchPressSaga(action) {
    try {
        const press = yield call(connect);
        const articles = press.val()

        yield put({
            type: SET_PRESS,
            all_articles: articles
        })
    }
    catch (error) {
        console.log("errors", error)

    }
}
