export const TEST_ACTION = "TEST_ACTION"
export const SET_TEST_ACTION = "SET_TEST_ACTION"

export const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const LOG_OUT_USER = 'LOG_OUT_USER'
export const SET_LOG_OUT_USER = 'SET_LOG_OUT_USER'
export const GET_UNITS = "GET_UNITS"
export const SET_UNITS = "SET_UNITS"
export const RESET_CONTACT_FORM = "RESET_CONTACT_FORM"
export const GET_CONTACT_FORM = "GET_CONTACT_FORM"
export const SET_CONTACT_FORM = "SET_CONTACT_FORM"

export const FETCH_UNITS = "FETCH_UNITS"

export const FETCH_CONTENT = "FETCH_CONTENT"
export const SET_CONTENT = "SET_CONTENT"

export const GET_PRESS = "GET_PRESS"
export const SET_PRESS = "SET_PRESS"

export const TOGGLE_MENU = 'TOGGLE_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'