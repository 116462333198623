import React from 'react'

const Legal = () => {
    return (
        <div className="legal">
            Equal Housing Opportunity. Renderings are artist images and reflect scale and spirit. Floorplans are for marketing purposes only. Purchasers may rely only on the Offering Plan, not marketing materials, when deciding whether to purchase a unit. All dimensions and square footages are approximate and subject to construction variances. All units are sold unfurnished. Sponsor reserves the right to make changes in accordance with the terms of the Offering Plan. The complete offering terms are in the Offering Plan, which is available from Sponsor. File No. CD19-0173. Sponsor: 157-161 East 28th Street LLC, 400 Broome Street, 11th Floor, New York, New York 10013.
        </div>
    )
}

export default Legal