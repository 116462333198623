import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client'

const httpLink = new HttpLink({ uri: process.env.REACT_APP_CMS_ENDPOINT })

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: 'Bearer ' + process.env.REACT_APP_CMS_TOKEN,
        },
    })

    return forward(operation)
})

export default new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
    }),
    link: concat(authMiddleware, httpLink),
})