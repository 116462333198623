import React from 'react'
import Routing from './Routing'
import { Router } from "react-router-dom";
import queryString from 'query-string';
import {createBrowserHistory} from 'history';

function preserveQueryParameters(history, location) {
    const currentQuery = queryString.parse(history.location.search);
    
    if (currentQuery) {
        location.search = queryString.stringify(currentQuery);
    }

    return location;
}

function createLocationDescriptorObject(location, state) {
    return typeof location === 'string' ? { pathname: location, state } : location;
}

function createPreserveQueryHistory () {
    const history = createBrowserHistory();
    const oldPush = history.push, oldReplace = history.replace;
    history.push = (path, state) => oldPush.apply(history, [preserveQueryParameters(history, createLocationDescriptorObject(path, state))]);
    history.replace = (path, state) => oldReplace.apply(history, [preserveQueryParameters(history, createLocationDescriptorObject(path, state))]);
    return history;
}

const history = createPreserveQueryHistory();

export default () => (
    <Router history={history}>
        <Routing />
    </Router>
);