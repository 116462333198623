import React, { Component, useRef, useMemo, useState, useCallback, useEffect } from 'react'
import $ from 'jquery';
import { Row, Col } from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import ReactSVG from 'react-svg'
import { useMediaQuery } from 'react-responsive'
import { useQuery, gql } from '@apollo/client'
import { ReactComponent as BuildingSvg } from "../../assets/images/SVG/building.svg"
import floorplanSVG from "../../assets/images/plan.svg"
import "./availability.scss"

const LIST_UNITS = gql`
    query listUnits {
        unitCollection {
            items {
                number
                key: number
                beds
                baths
                intSqFt
                extSqFt
                price
                streetFacing
                floorplan {
                    url
                }
            }
        }
    }
`

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const formatSqFt = x => {
    return !x ? '-' : numberWithCommas( parseInt(x).toFixed(0) )
}

const Data = ({ onResize, onMouseEnter, onMouseLeave }) => {
    const tableContainerRef = useRef()

    const { data: listUnitsData } = useQuery(LIST_UNITS)
    
    const [ sortColumn, setSortColumn ] = useState('price')
    const [ sortOrder, setSortOrder ] = useState('ASC')

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })

    const data = useMemo(() => {
        if (listUnitsData && listUnitsData.unitCollection && listUnitsData.unitCollection.items) {
            return listUnitsData.unitCollection.items.slice().sort((a, b) => {
                if (sortOrder === 'DESC') {
                    return a[sortColumn] > b[sortColumn] ? -1 : 1;
                }
                else {
                    return a[sortColumn] > b[sortColumn] ? 1 : -1;
                }
            })
        }

        return []
    }, [ listUnitsData, sortColumn, sortOrder ])

    const resize = useCallback(() => {
        if (tableContainerRef.current && !isMobile) {
            onResize(tableContainerRef.current.offsetHeight * 0.5006834727)
        }
    }, [ tableContainerRef, onResize, isMobile ])

    useEffect(() => {
        if (listUnitsData) {
            resize()
        }
    }, [ listUnitsData, resize ])

    useEffect(() => {
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [ resize ])

    const columns = useMemo(() => {
        const _columns = [
            {
                key: 'number',
                label: 'Residence',
                sortable: true,
            }
        ]

        if (isMobile) {
            _columns.push({
                key: 'beds',
                label: 'Br/Ba',
                sortable: true,
            })
        }
        else {
            _columns.push(
                {
                    key: 'beds',
                    label: 'Beds',
                    sortable: true,
                },
                {
                    key: 'baths',
                    label: 'Baths',
                    sortable: true,
                },
            )
        }
        
        return [
            ..._columns,
            {
                key: 'intSqFt',
                label: 'Int.SqFt',
                sortable: true,
            },
            {
                key: 'extSqFt',
                label: 'Ext.SqFt',
                sortable: true,
            },
            {
                key: 'price',
                label: 'Price',
                sortable: true,
            },
            {
                key: 'floorplan',
                label: 'Floorplan',
                sortable: false,
            },
        ]
    }, [ isMobile ])

    const changeSort = useCallback(col => {
        if (col != sortColumn) {
            setSortColumn(col)
        }
        else {
            setSortOrder(sortOrder === 'DESC' ? 'ASC' : 'DESC')
        }
    }, [ sortColumn, sortOrder, setSortColumn, setSortOrder ])

    return (
        <div className="unit-table-container" ref={tableContainerRef}>
            <Style data={data} />

            <table className="unit-table">
                <thead>
                    <tr>
                        {columns.map(col =>
                            <th
                                key={col.key}
                                className={sortColumn === col.key ? 'sorting sortable' : col.sortable ? 'sortable' : ''}
                                onClick={() => col.sortable && changeSort(col.key)}
                            >
                                <span className="column-label">{col.label}</span>

                                {
                                    col.sortable
                                        &&
                                    (
                                        sortOrder === 'ASC'
                                            ?
                                        <CaretUpFilled />
                                            :
                                        <CaretDownFilled />
                                    )
                                }
                            </th>
                        )}
                    </tr>
                </thead>

                <tbody>
                    {data.map(unit =>
                        <tr key={unit.number} onMouseEnter={e => onMouseEnter(e, unit)} onMouseLeave={e => onMouseLeave(e, unit)}>
                            <td className="heading">{unit.number}</td>
                            {
                                isMobile
                                    ?
                                <td>{unit.beds + '/' + unit.baths}</td>
                                    :
                                <>
                                    <td>{unit.beds}</td>
                                    <td>{unit.baths}</td>
                                </>
                            }
                            <td className="heading">{formatSqFt(unit.intSqFt)}</td>
                            <td className="heading">{formatSqFt(unit.extSqFt)}</td>
                            <td className="heading">${numberWithCommas(unit.price)}</td>
                            <td className="heading">
                                <a href={unit.floorplan.url} target="_blank" download>
                                    {
                                        !isDesktop
                                            ?
                                        <ReactSVG className="logo-plan-svg" src={floorplanSVG} />
                                            :
                                        'VIEW'
                                    }
                                </a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default class AvailabilityPage extends Component {
    constructor() {
        super()
        this.state = {
            searchText: '',
            main_container: 1,
            data: [],
            visible: false,
            photoIndex: 0,
            isOpen: false,
            loadedMagnifier: false,
            showBack: false,
            selectedUnit: null,
            buildingWidth: null,
        }
    }
    
    componentDidMount = () => {
        $(".header").removeClass("home-nav")
    }

    onMouseLeave = (event, record, rowIndex) => {
        this.setState({
            selectedUnit: null,
            showBack: false,
        })
    }

    onMouseEnter = (event, record, rowIndex) => {
        const { number, streetFacing } = record

        this.setState({
            selectedUnit: number,
            showBack: !streetFacing,
        })
    }
    
    loadedImage = (imageRef) => {
        this.setState({
            [imageRef]: $(`.${imageRef}`).height()
        })
    }

    onResize = buildingWidth => {
        this.setState({
            buildingWidth,
        })
    }

    render() {
        const { selectedUnit, showBack } = this.state
        
        return (
            <React.Fragment>
                <div className="availability-page">
                    <Row type="flex" justify="end">
                        <div className="main_container">
                            <div className="building-col" style={{ width: `${this.state.buildingWidth}px` }}>
                                <BuildingSvg
                                    className={`building-svg ${showBack ? 'building-back' : 'building-front'} ${selectedUnit ? 'building-' + selectedUnit : ''}`}
                                />
                            </div>
                            
                            <div className="table-col">
                                <Data onResize={this.onResize} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} />
                            </div>
                        </div>
                    </Row>
                </div>

            </React.Fragment >
        )
    }
}

const Style = React.memo(({ data }) => (
    <style>
        {data.map(record => `
            .building-${record.number} #u${record.number} .st0 {
                fill:#F95C4F;
            }

            .building-${record.number} #u${record.number} .st1 {
                fill:#F76D6D;
            }

            .building-${record.number} #u${record.number} .st2 {
                fill:#F48E8E;
            }

            .building-${record.number} #u${record.number} .st3 {
                fill:#F48787;
            }
        `)}
    </style>
))