import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { List, Button } from 'antd';
import { Link } from 'react-router-dom'
import "./press.scss"
import { useQuery, gql } from '@apollo/client'
import moment from 'moment-timezone'
import Footer from '../../components/Footer'
import $ from 'jquery';

const LIST_ARTICLES = gql`
  query listArticles {
    articleCollection(order: date_DESC) {
      items {
        sys {
          id
        }
        title
        subtitle
        source
        date
      }
    }
  }
`

const formatDate = date => moment(date).format('M/D/YYYY')

const PressPage = () => {
    const { data: listArticlesData } = useQuery(LIST_ARTICLES)

    const data = useMemo(() => {
        if (listArticlesData) {
            return listArticlesData.articleCollection.items
        }

        return []
    }, [ listArticlesData ])

    useEffect(() => {
        $(".header").removeClass("home-nav")
    })

    return (
        <React.Fragment>
            <div className="press-page"  >
                <div className="mainContent" >
                    {data.map(article =>
                        <div key={article.sys.id} className="article_container">
                            <div>
                                <div>
                                    <List itemLayout="vertical" >
                                        <List.Item
                                            actions={[<Link to={{ pathname: "/article", search: `?article=${article.sys.id}` }} >
                                                <Button className="submit-btn" type="primary" htmlType="submit">
                                                    Read more
                                                </Button>
                                            </Link>]}
                                        >
                                            <div>
                                                <div className="list_description author">
                                                    {article.source}, {formatDate(article.date)}
                                                </div>

                                                <br />

                                                <h3 className="heading">{article.title}</h3>

                                                <div className="list_description">{article.subtitle}<br /></div>
                                            </div>
                                        </List.Item>
                                    </List>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Footer className="light" />
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = state => ({
    state
})
const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PressPage)
