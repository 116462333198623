import { call, put } from "redux-saga/effects"
import { SET_UNITS } from "../actions"
import * as firebase from 'firebase/app';
import 'firebase/database';

function connect() {
    return new Promise(resolve => {
        const database = firebase.database();
        const connectionRef = database.ref('/');
        connectionRef.on('value', resolve);
    });
}
export function* fetchUnits(action) {
    try {
        const units = yield call(connect);
        yield put({
            type: SET_UNITS,
            units: units.val().floors,
            pages: units.val().content.pages
        })
    }
    catch (err) {
        console.log("err", err)
    }
}