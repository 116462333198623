import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import $ from 'jquery';
import Plx from 'react-plx';
import "./penthouses.scss"
import { HeaderText, ImageWithDescription, ImageWithHeaderAndDescription } from "../sections/Sections"
import Footer from '../../components/Footer'

const scrollEvent = (e) => {
    var frameNumber = Math.floor(window.pageYOffset)
    if (frameNumber < 10) {
        $(".animated-route").css({ "transform": "" })
    }
}

const Penthouses = (props) => {
    const [ isMounted, setIsMounted ] = useState(false)

    const isTouch = useMediaQuery({ query: '(hover: none)' })

    useEffect(() => {
        setIsMounted(true)

        window.addEventListener('scroll', scrollEvent)

        $(".animated-route").css({ "transform": "" })

        $(".header").removeClass("home-nav")

        return () => {
            setIsMounted(false)

            window.removeEventListener('scroll', scrollEvent)
        }
    }, [ setIsMounted ])
    
    const { page } = props

    return (
        <React.Fragment >
            <div className="penthouses-page">
                <div className="section_one_container hide-mobile">
                    < Plx
                        disabled={!isMounted || isTouch}
                        parallaxData={[{
                            start: 0,
                            duration: 300,
                            properties: [
                                {
                                    startValue: 0,
                                    endValue: 100,
                                    property: "translateY"
                                },]
                        }]}>
                        <div className="image_container">
                            <div className="image" style={{
                                content: 'url("/images/section_one.jpg")',
                            }} />
                        </div>
                    </ Plx>
                </div>
                <div className="penthouse_content" >
                    <div className="ph_section_two mobile-only">
                        <ImageWithHeaderAndDescription section={{ ...page.section_two, image: page.section_one.image }} />
                    </div>
                    <div className="ph_section_two hide-mobile">
                        <HeaderText section={page.section_two} />
                    </div>
                    <div className="ph_section_three">
                        <ImageWithDescription section={page.section_three} />
                    </div>
                    <div className="ph_ection_four">
                        <ImageWithDescription section={page.section_four} align="left" />
                    </div>

                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default Penthouses
