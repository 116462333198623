import React, { Component } from 'react'
import $ from 'jquery';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Waypoint } from 'react-waypoint'
import { Form, Input, Radio, Button, Row, Col, Modal, Select } from 'antd'
import MediaQuery from 'react-responsive'
import Corcoran from "../../assets/images/corcoran-logo.png"
import Forkosh from "../../assets/images/fdg.png"
import { getPress } from "../../state/actions/PressAction"

import "../../styles/contact.scss"
import { contactForm, contactFormReset } from "../../state/actions/EmailAction"
import { isMobile } from 'react-device-detect';

class Contact extends Component {
    _isMounted = false;

    constructor() {
        super()
        this.state = {
            loaded: false,
            buildingContainer: 1,
            contactContainer: 1,
            contactCotainerHeight: 1,
            thirdSectionImage: 1,
            mainContent: 1,
            secondRow: 1,
            firstRow: 1,
            End: [],
            Start: [],
            display: false,
            error: null,
            submitting: false,
            sent: false,
            confirmationVisible: false,
        }
        this.confirmationTimeout = null
    }
    UNSAFE_componentWillMount = () => {
        this.props.getPress()
    }
    componentDidMount = () => {
        this._isMounted = true;
        // $(".header").css("background", "#ffffff")
        // $(".icon-nav__line").css("background", "#606060")
        // $(".link-item").css("color", "#606060")
        // this.scrollEvent()
        console.log("mount contact")
        $(".header").removeClass("home-nav")
        window.addEventListener("resize", this.resizeEvent);

    }
    resizeEvent = () => {
        const contactContainer = $(".contactContainer").height()
        this.setState({
            contactContainer,
        })
    }


    loadedImage = (imageRef) => {

        this.setState({
            [imageRef]: $(`.${imageRef}`).height()
        })



    }
    onEnterFirst = (val) => {
        if (val.previousPosition === "above") {
        } else {
        }
    }
    onLeaveFirst = (val) => {
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    submitting: true,
                    error: false
                })
                this.props.contactForm(values)
            }
        });
    }
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    finalize = () => {
        this.props.contactFormReset()
        this.props.form.resetFields()
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const { sent } = newProps.ContactFormState.ContactForm
        if (sent) {
            this.setState({
                submitting: false,
                error: false,
                sent: true,
                confirmationVisible: true,
            })

            this.finalize()
        }
        else {
            this.setState({
                sent: false
            })

            clearTimeout(this.confirmationTimeout)

            this.confirmationTimeout = setTimeout(() => {
                this.setState({
                    confirmationVisible: false,
                })
            }, 2500)
        }
    }
    componentWillUnmount() {
        // this._isMounted = false;
        this._isMounted = false;

        clearTimeout(this.confirmationTimeout)
        window.removeEventListener('scroll', this.scrollEvent)
        window.removeEventListener("resize", this.resizeEvent);

    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { contactContainer, submitting, confirmationVisible } = this.state
        
        return (
            <React.Fragment>

                <div className="contact-page">
                    <div className="mainContent" >
                        <Waypoint >
                            <div  >
                                <Row >
                                    <div className="contactContainer contact-container">
                                        <Row type="flex" align="bottom" justify="end" className="form-row-container">
                                            <Col className="form-col">
                                                {/* <div className="form-title ">
                                                    <h1>THE PLACE TO LIVE WHEN
                                                    <br />YOU'RE GOING PLACES</h1>
                                                </div> */}
                                                <div className="contact-form-container">
                                                    <Form className="contact-form" id="contact-form" onSubmit={this.handleSubmit}>
                                                        {/* <p className="copy copy--full">Introducing Hillrose28, a new ground-up twenty-story residential tower in Manhattan's historic Rose Hill neighborhood, designed by global architectural design firm Lemay + Escobar and executed by Forkosh Development. Located between Lexington and Third Avenues, 181 East 28th Street offers the tranquility of a tree-lined community with central, walkable access to the city's destinations and transit options. Each home interprets the iconic tradition of New York condominium living through a modern lens, from the building's stately Portuguese limestone facade to each home's thoughtfully designed spaces. Many of the residences offer expansive outdoor space, an ideal amenity for those looking to make a home in the city, and the city <span className="nobr">their home.</span></p> */}

                                                        {/* <p className="copy copy--full copy--last">Join our priority list today to be the first learn of our updates on our studio to penthouse three <span className="nobr">bedroom residences.</span></p> */}

                                                        <p className="copy copy--last">Please register below to receive the most up-to-date information for Hillrose28, the place to live when you’re going places. Virtual and in-person visits are now available with all <span className="nobr">safety precautions.</span></p>

                                                        <div className="links">
                                                            <a className="heading" href="tel:+12128668887">(212) 866-8887</a>

                                                            <a className="heading" href="mailto:hello@hillrose28.com">hello@hillrose28.com</a>
                                                        </div>

                                                        <div className="inputs">
                                                            <Row gutter={16}>
                                                                <Col span={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('name', {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Required!',
                                                                                },
                                                                            ],
                                                                        })(
                                                                            <Input type="text" placeholder="Name *" />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col span={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('email_phone', {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Required!',
                                                                                },
                                                                            ],
                                                                        })(
                                                                            <Input type="text" placeholder="Email or Phone *" />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row gutter={16} type="flex" align="middle">
                                                                <Col span={12} className="broker-col">
                                                                    <Form.Item>
                                                                        <span className="broker-text">Broker*</span>

                                                                        {getFieldDecorator('broker', {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Required!',
                                                                                },
                                                                            ],
                                                                        })(
                                                                            <Radio.Group>
                                                                                <Radio className="radio-option" value="true">Yes</Radio>
                                                                                <Radio className="radio-option" value="false">No</Radio>
                                                                            </Radio.Group>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                
                                                                <Col span={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('residence_type')(
                                                                            <Select placeholder="Residence type">
                                                                                <Select.Option value="Studios">Studios</Select.Option>
                                                                                <Select.Option value="One Bedrooms">One Bedrooms</Select.Option>
                                                                                <Select.Option value="Two Bedrooms">Two Bedrooms</Select.Option>
                                                                                <Select.Option value="Three Bedrooms">Three Bedrooms</Select.Option>
                                                                                <Select.Option value="Penthouses">Penthouses</Select.Option>
                                                                            </Select>
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Form.Item>
                                                                    {getFieldDecorator('notes')(
                                                                        <Input type="text" placeholder="Notes" />
                                                                    )}
                                                                </Form.Item>
                                                            </Row>

                                                            <Form.Item >
                                                                <Button loading={submitting} className="submit-btn" type="primary" htmlType="submit">
                                                                    {submitting ? "" : "Submit"}
                                                                </Button>
                                                            </Form.Item>
                                                        </div>
                                                    </Form>

                                                    <div className="logos">
                                                        <a href="https://www.corcoransunshine.com/currentdev.php">
                                                            <img src={Corcoran} className="logo" alt="Corcoran Sunshine" />

                                                        </a>
                                                        
                                                        <a href="https://www.forkoshdevelopmentgroup.com">
                                                            <img src={Forkosh} className="logo" alt="Forkosh Development Group" />
                                                        </a>

                                                    </div>

                                                    <p className="terms">Equal Housing Opportunity. The complete offering terms are in the Offering Plan, which is available from Sponsor. File No. CD19-0173. Sponsor: 157-161 East 28th Street LLC, 400 Broome Street, 11th Floor, New York, New York 10013.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                            </div>
                        </Waypoint>
                    </div>

                </div>
                <Modal visible={confirmationVisible} footer={null} closable={false} maskClosable={false}>Thank you for signing up for priority information for Hillrose28</Modal>

            </React.Fragment >
        )
    }
}
const ContactForm = Form.create({ name: 'contact' })(Contact)

const mapStateToProps = state => ({
    ContactFormState: state

})

const mapDispatchToProps = dispatch => bindActionCreators({
    contactForm, contactFormReset, getPress
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
