import { SET_UNITS } from "../actions"

const initialState = {
    units: null,
    pages: null
}

export default (state = initialState, action) => {
    if (action.type === SET_UNITS) {
        state = {
            ...state,
            units: action.units,
            pages: action.pages
        }
    }
    return state
}