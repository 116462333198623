import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'

const pages = [
    'home',
    'residences',
    // 'penthouses',
    'amenities',
    'neighborhood',
    'availability',
    'team',
    'press',
    'contact',
]

const Footer = (props) => {
    const location = useLocation()

    const route = useMemo(() => {
        return location.pathname.replace(/^\//, '') || 'home'
    }, [ location ])

    const next = useMemo(() => {
        const index = pages.indexOf(route)

        return pages[index + 1] || ''
    }, [ route ])
    
    return (
        <footer {...props}>
            <div>
                <p>157-161 East 28th Street LLC and Exclusive Sales and Marketing Agent fully support<br />the principles of the Fair Housing Act and the Equal Opportunity Act.</p>

                <div className="links">
                    <a href="https://www.dos.ny.gov/press/2020/docs/FairHousingNotice_new.pdf?j=465853&sfmc_sub=97792223&l=122_HTML&u=10033327&mid=100004597&jb=4" target="_blank" rel="noopener noreferrer">Fair Housing Notice</a>
                    <a href="/privacy">Privacy</a>
                    <a href="/legal">Legal</a>
                </div>
            </div>
            
            <div className="next">
                <Link to={`/${next}`}>{next}</Link>

                <RightOutlined />
            </div>
        </footer>
    )
}

export default Footer