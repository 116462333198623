import { SET_PRESS } from "../actions"

const initialState = {
    all_articles: []
}

export default (state = initialState, action) => {
    if (action.type === SET_PRESS) {
        state = {
            ...state,
            all_articles: action.all_articles
        }
    }
    return state
}