import "react-responsive-carousel/lib/styles/carousel.min.css"

import React, { useState, useEffect, useCallback, useRef } from 'react'
import $ from 'jquery';
import { Waypoint } from 'react-waypoint';
import { HeaderText, ImageOnly, ImageWithDescription } from "../sections/Sections"
import { useMediaQuery } from 'react-responsive'
import Plx from 'react-plx';
import { Carousel } from 'react-responsive-carousel'
import Footer from '../../components/Footer'
import "./residences.scss"
let page_height = $(window).height()

const page = {
    // section_one: { image: '/images/new/181E28_lr4_WEB.jpg' },
    section_two: {
        images: [
            '/images/new/181E28_lr4_WEB.jpg',
            '/images/new/181E28_balcony2_WEB.jpg',
            '/images/new/181E28_lr6_WEB.jpg',
            '/images/new/181E28_lr2_WEB.jpg',
            '/images/new/181E28_lr1_WEB.jpg',
            '/images/new/181E28_br4_WEB.jpg',
            '/images/new/181E28_br3_WEB.jpg',
        ],
    },
    section_three: {
        header_one: 'RESIDENCES',
        header_two: 'FEEL RIGHT. AT HOME.',
        paragraph: 'Hillrose28 features a limited collection of 43 full-service residences, designed for those who want to get the most out of the city living experience. The studio-to-three-bedroom homes celebrate the history of classic New York-style living in a refreshingly updated way. The interiors feature wide plank, white oak flooring, solid wood doors, and oversized windows for a bright, airy, and open feeling, complemented by refined materials and modern details found throughout.',
    },
    section_four: {
        paragraph: 'Each residence features an open plan kitchen custom-designed by Andres Escobar and produced by Scavolini, ideal for dining in or entertaining others. The layouts center around distinguished Statuary marble slab countertops and thoughtfully considered storage. Oak cabinetry with recessed pulls and brushed nickel details, as well as accent cabinets with ribbed and etched glass balance sophistication with function.',
        image: '/images/new/181E28_kit2A_WEB_181E28_kit1_WEB.jpg',
        images: ['/images/new/181E28_kit2A_WEB.jpg', '/images/new/181E28_kit1_WEB.jpg'],
    },
    section_five: {
        paragraph: 'Master bathrooms feature Italian white marble, and each showcases a custom-designed vanity by Andres Escobar and cast iron tubs or custom showers with glass doors.',
        image: '/images/new/181E28_bath1A_WEB_181E28_bath2_WEB.jpg',
        images: ['/images/new/181E28_bath1A_WEB.jpg', '/images/new/181E28_bath2_WEB.jpg'],
    },
    section_six: {
        paragraph: 'The powder room and secondary baths both feature Italian marble accent walls and Andres Escobar-designed custom-stained oak vanities.',
        image: 'https://firebasestorage.googleapis.com/v0/b/hillrose28-413ba.appspot.com/o/amenities%2Fpowder_room.png?alt=media&token=a230938e-0a2c-41bf-81ac-3654eb214617',
    },
};

const Residences = () => {
    const [ state, setState ] = useState({
        start_bath_animation_at: 9000,
        start_kitchen_animation_at: 9000,
        isMounted: false,
    })

    const kitchenRef = useRef();
    const bathRef = useRef();

    const isTouch = useMediaQuery({ query: '(hover: none)' })

    const onEnterFirst = useCallback(() => {
        let page_width = $(window).width()
        if (page_width >= 1025) {
            if (!isTouch) {
                $("#residence_page #main_content").removeClass("main_content_relative")
            }
        }
    }, [ isTouch ])

    const onLeaveFirst = useCallback(() => {
        let page_width = $(window).width()

        if (page_width >= 1025) {
            // let start_bath_animation_at = $('.re_section_four').offset().top - $(".inner").outerHeight();
            // setState({ start_bath_animation_at })

            if (!isTouch) {
                $("#residence_page #main_content").addClass("main_content_relative")
            }
        }

    }, [ setState, isTouch ])

    useEffect(() => {
        if (bathRef.current) {
            setState(prevState => ({
                ...prevState,
                start_bath_animation_at: bathRef.current.element.getBoundingClientRect().top,
            }))
        }
    }, [ bathRef, setState ]);

    useEffect(() => {
        if (kitchenRef.current) {
            setState(prevState => ({
                ...prevState,
                start_kitchen_animation_at: kitchenRef.current.element.getBoundingClientRect().top,
            }))
        }
    }, [ kitchenRef, setState ]);

    useEffect(() => {
        $(".header").removeClass("home-nav")

        setState(prevState => ({
            ...prevState,
            isMounted: true,
        }))

        return () => {
            setState(prevState => ({
                ...prevState,
                isMounted: false,
            }))
        }
    }, [ setState ])
    
    return (
        <div id="residence_page" >
            {/* {
                !isTouch
                    &&
                <Waypoint
                    topOffset={$(".inner").outerHeight() + 1}
                    onEnter={(val) => onEnterFirst(val)}
                    onLeave={(val) => onLeaveFirst(val)}
                >
                    <div className="re_section_one hide-mobile">
                        <ImageOnly section={page.section_one} />
                    </div>
                </Waypoint>
            } */}
            
            <div id="main_content" className="main_content_relative">
                {/* <div id="section_two"> */}
                    {/* {
                        isTouch
                            ?
                        <div className="re_section_two">
                            <div id="image_only">
                                <Carousel
                                    showArrows={false}
                                    showThumbs={false}
                                    showStatus={false}
                                >
                                    <div>
                                        <img src={page.section_one.image} />
                                    </div>
                                    
                                    <div>
                                        <img src={page.section_two.image} />
                                    </div>
                                </Carousel>
                            </div>
                            
                            <HeaderText section={page.section_three} />
                        </div>
                            :
                        <Plx
                            disabled={!state._isMounted}
                            parallaxData={[{
                                start: page_height,
                                duration: page_height * 1.7,
                                properties: [
                                    {
                                        startValue: 1,
                                        endValue: 0,
                                        property: 'opacity',
                                    },
                                ],
                            },]}
                        > */}
                            <div className="re_section_two">
                                {/* <ImageOnly section={page.section_two} /> */}

                                <div id="image_only">
                                    <Carousel
                                        showThumbs={false}
                                        showStatus={false}
                                        showIndicators={isTouch}
                                        showArrows={!isTouch}
                                        useKeyboardArrows={true}
                                        autoPlay={true}
                                        infiniteLoop={true}
                                    >
                                        {page.section_two.images.map(image =>
                                            <div>
                                                <img src={image} />
                                            </div>
                                        )}
                                    </Carousel>
                                </div>

                                <HeaderText section={page.section_three} />
                            </div>
                        {/* </Plx>
                    }
                </div> */}

                <div className="re_section_three">
                    {
                        isTouch
                            ?
                        <>
                            <div id="image_only">
                                <Carousel
                                    showArrows={false}
                                    showThumbs={false}
                                    showStatus={false}
                                >
                                    {page.section_four.images.map(image =>
                                        <div>
                                            <img src={image} />
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                            
                            <HeaderText section={page.section_four} />
                        </>
                            :
                        <div id="image_with_description" className="align-left">
                            <div className="image_container">
                                <Plx
                                    className="image"
                                    ref={kitchenRef}
                                    style={{
                                        backgroundImage: `url(${page.section_four.image})`
                                    }}
                                    parallaxData={[
                                        {
                                            start: state.start_kitchen_animation_at,
                                            duration: 1000,
                                            properties: [
                                                {
                                                    startValue: 0,
                                                    endValue: 100,
                                                    unit: "%",
                                                    property: 'backgroundPositionX',
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            </div>

                            <div className="paragraph_container">
                                <p>{page.section_four.paragraph}</p>
                            </div>
                        </div>
                    }
                </div>

                <div className="re_section_four">
                    {
                        isTouch
                            ?
                        <>
                            <div id="image_only">
                                <Carousel
                                    showArrows={false}
                                    showThumbs={false}
                                    showStatus={false}
                                >
                                    {page.section_five.images.map(image =>
                                        <div>
                                            <img src={image} />
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                            
                            <HeaderText section={page.section_five} />
                        </>
                            :
                        <div id="image_with_description">
                            <div className="image_container last_image_container">
                                <Plx
                                    disabled={isTouch}
                                    ref={bathRef}
                                    id="image_only"
                                    className="hide-mobile"
                                    style={{
                                        backgroundImage: `url(${page.section_five.image})`
                                    }}
                                    parallaxData={[
                                        {
                                            start: state.start_bath_animation_at,
                                            duration: 1000,
                                            properties: [
                                                {
                                                    startValue: 0,
                                                    endValue: 100,
                                                    unit: "%",
                                                    property: 'backgroundPositionX',
                                                },
                                            ],
                                        },
                                    ]}
                                />

                                <img id="image_only" className="mobile-only" src={page.section_five.image} />
                            </div>
                            <div className="paragraph_container">
                                <p>{page.section_five.paragraph}</p>
                            </div>
                        </div>
                    }
                </div>

                <div className="res_section_five">
                    <ImageWithDescription section={page.section_six} align="left" />
                </div>

                <Footer />
            </div>
        </div >
    )
}

export default Residences