import React from "react"
import "./sections.scss"
export const HeaderText = ({ section }) => {
    return (
        <div id="header_text">
            <h3 className="heading">{section.header_one}</h3>
            <h3 className="heading">{section.header_two}</h3>
            <p className="description">{section.paragraph}</p>
        </div>
    )
}
export const ImageWithDescription = ({ section, align = 'right' }) => {
    return (
        <div id="image_with_description" className={`align-${align}`}>
            <div className="image_container">
                <div className="image" style={{
                    content: `url(${section.image})`
                }} />
            </div>
            <div className="paragraph_container">
                <p>{section.paragraph}</p>
            </div>
        </div >
    )
}

export const ImageWithHeaderAndDescription = ({ section }) => {
    return (
        <div id="image_with_description">
            <div className="image_container">
                <div className="image" style={{
                    content: `url(${section.image})`
                }} />
            </div>
            <div className="paragraph_container">
                <div id="header_text">
                    <h3 className="heading">{section.header_one}</h3>
                    <h3 className="heading">{section.header_two}</h3>
                    <p className="description">{section.paragraph}</p>
                </div>
            </div>
        </div >
    )
}

export const ImageOnly = ({ section }) => {
    return (
        <div id="image_only">
            <div className="image_container">
                <div className="image" style={{
                    content: `url(${section.image})`
                }} />
            </div>
        </div >
    )
}

