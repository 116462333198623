import React from 'react'
import { Icon } from 'antd'
import { useQuery, gql } from '@apollo/client'
import "./article.scss"

const GET_ARTICLE = gql`
    query getArticle($articleId: String!) {
        article(id: $articleId) {
            title
            blurb
            pdf {
                url
            }
            link
        }
    }
`

const ArticlePage = (props) => {
    const params = new URLSearchParams(props.location.search)
    
    const { data } = useQuery(GET_ARTICLE, {
        variables: {
            articleId: params.get('article'),
        },
    })

    if (!data) {
        return null
    }
    
    return (
        <div className="article-page">
            <div className="mainContent">
                <div className="articleContainer">
                    <h3 className="heading">
                        <span className="contact-header-text">{data.article.title}</span>
                    </h3>

                    <div className="description">
                        {data.article.blurb}

                        <p className="download-p">
                            <a rel="noopener noreferrer" href={data.article.pdf.url} target="_blank" className="pdf-btn" size="small" download icon="download">
                                <Icon type="download" /> Download PDF
                            </a>

                            <a rel="noopener noreferrer" href={data.article.link} target="_blank" className="pdf-btn" size="small">Source</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ArticlePage