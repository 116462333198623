import React, { useEffect } from 'react'
import { Route, Switch, Redirect, withRouter, useLocation } from 'react-router-dom'
import { useTransition, animated } from "react-spring";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeMenu } from '../state/actions/AppAction'
import Navigation from "./Navigation"
import ArticlePage from "../pages/Article/ArticlePage"
import ResidencesPage from "../pages/Residences/Residences"
import PentHousesPage from "../pages/Penthouses/PentHouses"
import HomePage from '../pages/Home/HomePage'
import AmenitiesPage from '../pages/Amenities/Amenities'
import PressPage from '../pages/Press/PressPage'
import ContactPage from '../pages/Contact/Contact'
import AvailabilityPage from '../pages/Availability/Availability'
import Neighborhood from "../pages/Neighborhood/Neighborhood"
import TeamPage from '../pages/Team/Team'
import Privacy from "../pages/Privacy/Privacy"
import Legal from "../pages/Legal/Legal"
// import $ from 'jquery';
// import ScrollToTop from 'react-router-scroll-top'
// import "../styles/layout/layout.scss"
// import $ from 'jquery';
// import { withRouter } from "react-router-dom";

// class ScrollToTop extends React.Component {
//     componentDidUpdate(prevProps) {
//         if (
//             this.props.location.pathname !== prevProps.location.pathname
//         ) {
//             window.scrollTo(0, 0);
//         }
//     }

//     render() {
//         return null;
//     }
// }

// export default withRouter(ScrollToTop);
const Routing = (props) => {
    const location = useLocation();
    // const homeTransitions = useTransition(location, location => location.pathname, {
    //     from: { opacity: 0, },
    //     enter: { opacity: 1, },
    //     leave: { opacity: 1, }
    // });
    const pressTransitions = useTransition(location, location => location.pathname, {
        from: { opacity: 0, },
        enter: { opacity: 1, },
        leave: { opacity: 1, }
    });
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "auto"
        });
    }
    const sendPageView = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'pageview',
            'pagePath': window.location.pathname + window.location.search,
        });
    };
    useEffect(() => {
        scrollToTop()

        sendPageView();

        props.history.listen(() => {
            document.documentElement.scrollTop = 0
            props.closeMenu()

            sendPageView();
        })
    }, []);
    // console.log("rounting..", location.pathname)
    // $(".header").addClass("home-nav")

    // const slideDown = useTransition(location, location => location.pathname, {
    //     from: { transform: "translate(-100%, 0%)", },
    //     enter: { transform: "translate(0%, 0%)", },
    //     leave: { transform: "translate(0%, 0%)", },
    //     unique: true
    // });
    // console.log("props", props)
    const { menuOpen, pages } = props

    if (!pages) {
        return null
    }

    const { penthouses, home, residences, amenities, team, neighborhood, availability } = pages
    return (
        // <ScrollToTop>
        <Switch>
            <React.Fragment>
                {/* <div> */}
                    <Navigation location={location} />
                    {pressTransitions.map(({ item, props, key }) => (
                        <animated.div className={`animated-route slidable ${menuOpen ? 'slide' : ''}`} key={key} style={props}>
                            <Route exact path="/" render={(props) => <HomePage page={home} {...props} />} />
                            <Route exact path="/home" render={() => <Redirect to="/" />} />
                            <Route exact path="/residences" render={(props) => <ResidencesPage page={residences} {...props} />} />
                            <Route exact path="/press" render={(props) => <PressPage {...props} />} />
                            <Route exact path="/article" render={(props) => <ArticlePage {...props} />} />
                            <Route exact path="/amenities" render={(props) => <AmenitiesPage page={amenities} {...props} />} />
                            <Route exact path="/neighborhood" render={(props) => <Neighborhood page={neighborhood} {...props} />} />
                            <Route exact path="/team" render={(props) => <TeamPage page={team} {...props} />} />
                            <Route exact path="/penthouses" render={(props) => <PentHousesPage page={penthouses}  {...props} />} />
                            <Route exact path="/contact" render={(props) => <ContactPage {...props} />} />
                            <Route exact path="/availability" render={(props) => <AvailabilityPage page={availability} {...props} />} />
                            <Route exact path="/privacy" render={(props) => <Privacy {...props} />} />
                            <Route exact path="/legal" render={(props) => <Legal {...props} />} />
                        </animated.div>
                    ))}
                {/* </div> */}

            </React.Fragment>
        </Switch>
        // </ScrollToTop>
    );
};
const mapStateToProps = state => ({
    pages: state.fetchReducer.pages,
    menuOpen: state.app.menuOpen,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    closeMenu
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routing))
