import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "../state"
import AppContainer from "../containers/AppContainer"

const onResize = () => {
    const vh = window.innerHeight * 0.01

    document.documentElement.style.setProperty('--initial-vh', `${vh}px`)
}

export default () => {
    useEffect(() => {
        onResize()
        
        // window.addEventListener('resize', onResize)

        // return () => {
        //     window.removeEventListener('resize', onResize)
        // }
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppContainer />
            </PersistGate>
        </Provider>
    )
}