import "react-responsive-carousel/lib/styles/carousel.min.css"

import React, { useState, useCallback, useEffect } from 'react'
import $ from 'jquery';
import Plx from 'react-plx';
import { Waypoint } from 'react-waypoint';
import { Carousel } from 'react-responsive-carousel'
import { useMediaQuery } from 'react-responsive'
import Footer from '../../components/Footer'
import "./amenities.scss"
import { HeaderText, ImageOnly, ImageWithDescription } from "../sections/Sections"

const page = {
    section_one: {
        image: '/images/new/181E28_roof1_WEB.jpg',
    },
    section_two: {
        header_one: 'AMENITIES',
        header_two: 'FULL SERVICE. INSIDE AND OUT.',
        paragraph: "Well-scaled amenities and services enhance the Hillrose28 living experience. A luxurious common roof terrace with an onyx bar, grill, and elegant landscaping showcases a stunning 360-degree view of Manhattan, making it an ideal venue for relaxing and entertaining.",
    },
    section_three: {
        image: '/images/new/181E28_roof2_WEB.jpg',
        paragraph: 'A dedicated and naturally-lit resident lounge spotlights a Scavolini kitchen, wet-bar, and serene recessed fireplace with a comfortable seating area bathed in southern exposure.',
    },
    section_six: {
        image: "https://firebasestorage.googleapis.com/v0/b/hillrose28-413ba.appspot.com/o/amenities%2Fsection_six.jpg?alt=media&token=8117dc50-bd3c-46b6-bb0d-875b7b659582",
        paragraph: "The 24-hour, duplex gym offers dedicated cardio and weight rooms with bright wood flooring and natural light.",
    },
};

const Amenities = () => {
    const [ state, setState ] = useState({
        isMounted: false,
        start_first_animation_at: 9000,
        start_last_animation_at: 9000,
        prevUp: 0,
    })

    const isTouch = useMediaQuery({ query: '(hover: none)' })

    const onPlxFirstStart = useCallback(() => {
        let scroll_direction = state.prevUp < window.scrollY ? "down" : "up"

        console.log("scroll_direction", scroll_direction)
        if (scroll_direction === "up") {
            $(".amenities_page .mainContent .first_animation #image_only").css({ "position": "sticky", "top": "6rem" })
        }
        if (scroll_direction === "down") {
            // $(".amenities_page .mainContent .first_animation ").css({ "height": "80%" })
            $(".amenities_page .mainContent .first_animation #image_only").css({ "position": "sticky", "top": "6rem" })
        }

        setState(prevState => ({
            ...prevState,
            prevUp: window.scrollY,
        }))
    }, [ state, setState ])

    useEffect(() => {
        $(".header").removeClass("home-nav")

        let start_last_animation_at = $('.last_animation').offset().top - $(".inner").outerHeight()
        let start_first_animation_at = $('.first_animation').offset().top - $(".inner").outerHeight()
        
        setState(prevState => ({
            ...prevState,
            isMounted: true,
            prevUp: window.scrollY,
            prevDown: window.scrollY,
            start_last_animation_at,
            start_first_animation_at,
        }))

        return () => {
            $(".animated-route").css({ "transform": "" })

            setState(prevState => ({
                ...prevState,
                isMounted: false,
            }))
        }
    }, [ setState ])

    const { start_last_animation_at, start_first_animation_at } = state
    
    return (
        <React.Fragment >
            <div className="amenities_page" >
                <div className="mainContent" >
                    <div className="amen_section_one_header">
                        <ImageOnly section={page.section_one} />
                    </div>

                    <div className="amen_header_section" >
                        <HeaderText section={page.section_two} />
                    </div>
                    {/* 
                    <div className="amen_section_one">
                        <ImageWithDescription section={page.section_one} />
                    </div> */}

                    <Waypoint
                        topOffset={$(".inner").outerHeight()}
                    >
                        <div className="amen_section_two">
                            <ImageWithDescription section={page.section_three} />
                        </div>
                    </Waypoint>
                    <div className="first_animation">
                        {/* {
                            isTouch
                                ? */}
                            <img src="/images/new/181E28_lounge_WEB.jpg" />
                                {/* : */}
                            {/* <Plx
                                disabled={isTouch}
                                id="image_only"
                                style={{
                                    backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/hillrose28-413ba.appspot.com/o/amenities%2FSection_four.jpg?alt=media&token=575d5335-42e7-49dc-b2ee-df4d4bbc73ae"})`
                                }}
                                onPlxStart={() => onPlxFirstStart()}
                                parallaxData={[
                                    {
                                        start: start_first_animation_at,
                                        duration: 1000,
                                        properties: [
                                            {
                                                startValue: 0,
                                                endValue: 100,
                                                unit: "%",
                                                property: 'backgroundPositionX',
                                            },
                                        ],
                                    },
                                ]}
                            /> */}
                        {/* } */}
                    </div>

                    <div className="last_animation">
                        <div id="image_with_description">
                            <div className="image_container last_image_container">
                                <Plx
                                    // disabled={isTouch}
                                    id="image_only"
                                    style={{
                                        backgroundImage: `url(${page.section_six.image})`
                                    }}
                                    parallaxData={[
                                        {
                                            start: start_last_animation_at,
                                            duration: 1000,
                                            properties: [
                                                {
                                                    startValue: 0,
                                                    endValue: 100,
                                                    unit: "%",
                                                    property: 'backgroundPositionY',
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            </div>
                            <div className="paragraph_container">
                                <p>{page.section_six.paragraph}</p>
                            </div>
                        </div >
                    </div>

                    <Footer />
                </div>
            </div>
        </React.Fragment >
    )
}

export default Amenities
