import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import $ from 'jquery';
import { Waypoint } from 'react-waypoint';
import Images from "../../components/BuildingFrames"
import { isMobile, isTablet, } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'
import Plx from 'react-plx';
import { HeaderText, ImageOnly, ImageWithDescription } from "../sections/Sections"
import "./home.scss"
import Footer from '../../components/Footer'
import hero from '../../assets/images/hero.jpg'

const HomePage = ({ page }) => {
    const heroRef = useRef()

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const isTouch = useMediaQuery({ query: '(hover: none)' })
    
    const [ heroHeight, setHeroHeight ] = useState(1)

    const resizeEvent = useCallback(() => {
        setHeroHeight(heroRef.current.offsetHeight)
    }, [ heroRef, setHeroHeight ])

    const heroMultiplier = useMemo(() => isMobile ? 0.15 : 0.5, [ isMobile ])

    useEffect(() => {
        if (heroRef.current) {
            heroRef.current.addEventListener('load', resizeEvent)
        }

        return () => {
            if (heroRef.current) {
                heroRef.current.removeEventListener('load', resizeEvent)
            }
        }
    }, [ heroRef, resizeEvent ])

    useEffect(() => {
        window.addEventListener('resize', resizeEvent, { passive: true })

        resizeEvent()

        return () => {
            window.removeEventListener('resize', resizeEvent)
        }
    }, [ resizeEvent ])
    
    const onEnterBuilding = useCallback((val) => {
        if (val.previousPosition === "above") {
            $(".inner ").addClass("home_nav")
            $(".inner .nav--home").removeClass("nav_active").addClass("nav_inactive").show()
            $(".inner .small_navigation").addClass("nav_active").removeClass("nav_inactive").removeClass("hidden")
            $(".home-page .mainContent").css({ "position": "fixed", })
            $(".home-page").css({ "height": "5000px", })
            $(".inner .main_nav").addClass("hidden")
            $(".burger .icon-nav").removeClass("icon_nav_active")
        }
    }, [])

    const onLeaveBuilding = useCallback((val) => {
        console.log("left building...")
        $(".inner .nav--home").hide().addClass("nav_active").removeClass("nav_inactive").removeClass("hidden")
        $(".inner .small_navigation").removeClass("nav_active").addClass("hidden")
        $(".inner ").removeClass("home_nav")
        $(".home-page .mainContent").css({ "position": "relative", })
        $(".home-page").css("height", "calc(var(--vh, 1vh) * 100)")
        $(".inner .main_nav").removeClass("hidden")

    }, [])

    let page_height = $(window).height()

    let fade_out_plx = {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
    }

    let fade_out_plx_third = {
        startValue: 0,
        endValue: 100,
        unit: "%",
        property: 'backgroundPositionY',
    }

    return (
        <div className="home-page">
            <Waypoint
                onEnter={(val) => onEnterBuilding(val)}
                onLeave={(val) => onLeaveBuilding(val)}
            >
                <div className="building-container">
                    <img ref={heroRef} src={hero} />

                    <Plx
                        className="overlay"
                        parallaxData={[{
                            start: 0,
                            end: (heroHeight * heroMultiplier),
                            properties: [{
                                startValue: 1,
                                endValue: 0,
                                property: 'opacity'
                            }]
                        }]}
                    />

                    <div className="header-text">
                        <Plx
                            // disabled={isTouch}
                            parallaxData={[{
                                start: 0,
                                end: (heroHeight * heroMultiplier),
                                properties: [{
                                    startValue: 1,
                                    endValue: 0,
                                    property: 'opacity'
                                }]
                            }]}
                        >
                            <h3 className="heading">THE PLACE TO LIVE<br />WHEN YOU'RE GOING PLACES</h3>
                        </Plx>
                    </div>
                </div>
            </Waypoint>

            <Plx
                disabled={isTouch}
                parallaxData={[
                    {
                        start: 'self',
                        end: heroHeight,
                        properties: [
                            {
                                startValue: 0,
                                endValue: 1,
                                property: 'opacity',
                            },
                        ],
                    },
                ]}
            >
                <div className="mainContent">
                    <div className="sections">
                        <Plx
                            disabled={isTouch}
                            parallaxData={[{
                                start: heroHeight,
                                duration: ".home_section_two",
                                properties: [fade_out_plx],
                            },]}
                        >
                            <div className="home_section_two">
                                <ImageWithDescription section={page.section_two} />
                            </div>
                        </Plx>

                        <Plx
                            disabled={isTouch}
                            parallaxData={[{
                                start: ".home_section_four",
                                duration: ".home_section_three",
                                properties: [fade_out_plx],
                            },]}
                        >
                            <div className="home_section_three">
                                <div id="image_only">
                                    <div className="image_container">
                                        <Plx
                                            className="image_animated"
                                            style={{ backgroundImage: `url(${page.section_three.image})` }}
                                            parallaxData={[{
                                                start: heroHeight + (page_height * .25),
                                                duration: 500,
                                                properties: [fade_out_plx_third],
                                            },]}
                                        />
                                    </div>

                                    <HeaderText section={page.section_four} />
                                </div>
                            </div>
                        </Plx>

                        <div className="home_section_four" >
                            <ImageWithDescription section={page.section_five} />
                        </div>
                    </div>
                    
                    <Footer />
                </div>
            </Plx>
        </div>
    )
}

export default HomePage