import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleMenu } from '../state/actions/AppAction'
import Logo from "../assets/images/SVG/logo.svg"
import ReactSVG from 'react-svg'
import { Link } from 'react-router-dom'
import { Drawer, } from 'antd';
import $ from "jquery"
import "../styles/layout/navigation.scss"
// let pageWidth = $(window).width()
// let isResponsive = (pageWidth >= 320 && pageWidth <= 1024) ? true : false
// let isDesktop = (pageWidth >= 1025) ? true : false
// console.log("isDesktop", isDesktop)
// console.log("isResponsive", isResponsive)
const Burger = ({ onClick, className, active }) => {
    return (
        <div
            onClick={onClick}
            className={`burger ${className}`}
        >
            <span className={`icon-nav ${active ? 'icon_nav_active' : ''}`}>
                <i className="icon-nav__line menu_icon"></i>
                <i className="icon-nav__line menu_icon"></i>
                <i className="icon-nav__line menu_icon"></i>
            </span>
        </div>
    )
}

const onBurgerClick = () => {
    $(".burger .icon-nav").toggleClass("icon_nav_active")
    $(".inner .nav--home").toggleClass("nav_inactive").toggleClass("nav_active")
    $(".inner .small_navigation").toggleClass("nav_inactive").toggleClass("nav_active")
}

class Nav extends Component {
    constructor(props) {
        super()
        this.state = {
            icon_nav_active: false,
            showMobileMenu: $(window).width() < 1025 ? true : false
        }
    }
    toggleMenu = () => {
        const { icon_nav_active } = this.state
        if (icon_nav_active) {
            this.setState(prevState => ({
                icon_nav_active: !prevState.icon_nav_active
            }));
        }

        document.body.classList.remove('nav-open')
    }
    toggleMenuOpen = () => {
        this.props.toggleMenu()
        
        document.body.classList.toggle('nav-open')
    }
    toggleMenuIcon = () => {
        this.setState(prevState => ({
            icon_nav_active: !prevState.icon_nav_active
        }));

    }
    resizeEvent = () => {
        let page_width = $(window).width()
        const { pathname } = this.props.location
        this.setState({ showMobileMenu: page_width < 1025 ? true : false, home: pathname === "/" ? "home_menu" : "" })
    }
    componentDidMount = () => {
        window.addEventListener("resize", this.resizeEvent);
        let page_width = $(window).width()
        const { pathname } = this.props.location
        this.setState({ showMobileMenu: page_width < 1025 ? true : false, home: pathname === "/" ? "home_menu" : "" })
    }
    render() {
        let page_width = $(window).width()
        const { icon_nav_active, } = this.state
        const { menuOpen } = this.props
        const { pathname } = this.props.location
        const items = ["home", "residences", /*"penthouses",*/ "amenities", "neighborhood", "availability", "team", "press", "contact"]
        const small_nav = ["availability", "contact"]
        const home = pathname === "/" ? true : false
        const route = pathname.replace(/^\//, '') || 'home'
        console.log("are we home?", home)


        return (
            <React.Fragment>
                <nav className={`nav--mobile slidable ${menuOpen ? 'slide' : ''}`}>
                    {Object.values(items).map(key => {
                        return <Link key={key} onClick={() => this.toggleMenu()} className="link heading" to={`/${key === "home" ? "" : key}`}>{key}</Link>
                    })}

                    <a href="tel:12120001111" className="link heading link--call">Call</a>
                </nav>

                <header className={`header header--main`}>
                    <div className={`inner ${home && "home_nav"} slidable ${menuOpen ? 'slide' : ''}`}>
                        <Burger onClick={this.toggleMenuOpen} className="mobile-only large" active={menuOpen} />

                        {home && <Burger className="hide-mobile" onClick={home && onBurgerClick} />}

                        {home &&
                            <>
                                <nav className={`nav small_navigation nav--gnb nav_active hide-mobile`}  >
                                    {Object.values(small_nav).map(key => {
                                        return (
                                            <Link
                                                key={key}
                                                onClick={() => this.toggleMenu()}
                                                className={`nav__elem `}
                                                to={`/${key === "home" ? "" : key}`}>
                                                {key}
                                            </Link>
                                        )
                                    })}
                                </nav>

                                <nav className={`nav nav--home ${home ? "nav_inactive" : "nav_active"} hide-mobile`}  >
                                    {Object.values(items).map(key => {
                                        return <Link key={key} onClick={() => this.toggleMenu()} className={`nav__elem ${route === key ? 'nav__active' : ''}`} to={`/${key === "home" ? "" : key}`}>{key}</Link>
                                    })}
                                </nav>
                            </>
                        }
                        <nav className={`nav main_nav nav_active ${home ? "hidden" : ""} hide-mobile`}  >
                            {Object.values(items).map(key => {
                                return <Link key={key} onClick={() => this.toggleMenu()} className={`nav__elem ${route === key ? 'nav__active' : ''}`} to={`/${key === "home" ? "" : key}`}>{key}</Link>
                            })}
                        </nav>
                        {/* <nav className={`nav small_navigation nav_inactive`} >
                            {Object.values(small_nav).map(key => {
                                return (
                                    <Link
                                        key={key}
                                        onClick={() => this.toggleMenu()}
                                        className={`nav__elem `}
                                        to={`/${key === "home" ? "" : key}`}>
                                        {key}
                                    </Link>
                                )
                            })}
                        </nav>
                        <nav
                            className={`nav main_nav nav--gnb nav_active`}
                        >
                            {Object.values(items).map(key => {
                                return <Link key={key} onClick={() => this.toggleMenu()} className={`nav__elem `} to={`/${key === "home" ? "" : key}`}>{key}</Link>
                            })}
                        </nav> */}
                        {/* <nav className={`nav nav--gnb ${!icon_nav_active ? pathname === "/" ? "nav_active" : "nav_inactive" : "nav_inactive"} nav-only-home`}>
                            <Link onClick={() => this.toggleMenu()} className={`small_menu ${pathname === "/" ? "link-item nav__elem nav__elemHome" : "link-item nav__elem "}`} to={"/availability"}>Availability</Link>
                            <Link onClick={() => this.toggleMenu()} className={`small_menu ${pathname === "/" ? "link-item nav__elem nav__elemHome" : "link-item nav__elem "}`} to={"/contact"}>Contact</Link>
                        </nav> */}

                        <h3 className="branding heading">
                            <span className="building-number">181</span>
                            <span className="hillrose">Hillrose</span>
                            <span className="street-number">28</span>
                        </h3>
                    </div>
                    {/* <Drawer
                        width="80%"
                        closable={true}
                        maskClosable={true}
                        placement={"left"}
                        onClose={this.toggleMenu}
                        visible={page_width <= 1025 ? icon_nav_active : false}
                    >
                        {
                            Object.values(items).map(key => {
                                return <React.Fragment key={key} >
                                    <Link onClick={() => this.toggleMenu()} className="link-item nav__elem" to={`/${key === "home" ? "" : key}`}>{key}</Link>   <br />
                                </React.Fragment>
                            })
                        }
                    </Drawer> */}
                </header >
            </React.Fragment >

        )
    }
}

const mapStateToProps = state => ({
    menuOpen: state.app.menuOpen,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    toggleMenu,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
