
// import image0001 from "../assets/images/building_frames/01.jpg"
import image0002 from "../assets/images/building_frames/02.jpg"
import image0003 from "../assets/images/building_frames/03.jpg"
import image0004 from "../assets/images/building_frames/04.jpg"
import image0005 from "../assets/images/building_frames/05.jpg"
import image0006 from "../assets/images/building_frames/06.jpg"
import image0007 from "../assets/images/building_frames/07.jpg"
import image0008 from "../assets/images/building_frames/08.jpg"
import image0009 from "../assets/images/building_frames/09.jpg"
import image0010 from "../assets/images/building_frames/10.jpg"
import image0011 from "../assets/images/building_frames/11.jpg"
import image0012 from "../assets/images/building_frames/12.jpg"
import image0013 from "../assets/images/building_frames/13.jpg"
import image0014 from "../assets/images/building_frames/14.jpg"
import image0015 from "../assets/images/building_frames/15.jpg"
import image0016 from "../assets/images/building_frames/16.jpg"
import image0017 from "../assets/images/building_frames/17.jpg"
import image0018 from "../assets/images/building_frames/18.jpg"
import image0019 from "../assets/images/building_frames/19.jpg"
import image0020 from "../assets/images/building_frames/20.jpg"
import image0021 from "../assets/images/building_frames/21.jpg"
import image0022 from "../assets/images/building_frames/22.jpg"
import image0023 from "../assets/images/building_frames/23.jpg"
import image0024 from "../assets/images/building_frames/24.jpg"
import image0025 from "../assets/images/building_frames/25.jpg"
import image0026 from "../assets/images/building_frames/26.jpg"
import image0027 from "../assets/images/building_frames/27.jpg"
import image0028 from "../assets/images/building_frames/28.jpg"
import image0029 from "../assets/images/building_frames/29.jpg"
import image0030 from "../assets/images/building_frames/30.jpg"
export default [
    // image0001,
    image0002,
    image0003,
    image0004,
    image0005,
    image0006,
    image0007,
    image0008,
    image0009,
    image0010,
    image0011,
    image0012,
    image0013,
    image0014,
    image0015,
    image0016,
    image0017,
    image0018,
    image0019,
    image0020,
    image0021,
    image0022,
    image0023,
    image0024,
    image0025,
    image0026,
    image0027,
    image0028,
    image0029,
    image0030,
]
