import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from 'redux-persist/lib/storage'
import fetchReducer from "./fetchReducer"

import pressReducer from "./PressReducer"
import ContactForm from './EmailReducer'
import AppReducer from './AppReducer'


const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: ['app',]
}

const reducers = combineReducers({
    fetchReducer, ContactForm, pressReducer, app: AppReducer
})

export default persistReducer(rootPersistConfig, reducers)