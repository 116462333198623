
import React, { Component } from 'react'
import App from "../components/App.js"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getPress } from ".././state/actions/PressAction"
import { fetchUnits } from ".././state/actions/fetchAction"
import { ApolloProvider } from '@apollo/client'
import client from '../services/apollo'


class AppContainer extends Component {
    componentDidMount() {
        this.props.getPress()
    }
    UNSAFE_componentWillMount() {
        this.props.fetchUnits()
    }
    render() {
        return (
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        )
    }
}
const mapStateToProps = state => ({
    state
})

const mapDispatchToProps = dispatch => bindActionCreators({ getPress, fetchUnits }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)

