import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'

const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
})

export default ({ data, selectedCategories }) => {
    const mapContainerRef = useRef()
    const mapRef = useRef()

    const [ layers, setLayers ] = useState([])
    const [ mapLoaded, setMapLoaded ] = useState(false)
    const [ styleLoaded, setStyleLoaded ] = useState(false)

    const isTouch = useMediaQuery({ query: '(hover: none)' })

    const clearLayers = useCallback((_layers) => {
        if (mapLoaded && styleLoaded) {
            for (let layer of layers) {
                if (!_layers.includes(layer)) {
                    mapRef.current.setLayoutProperty(layer, 'visibility', 'none')
                }
            }
        }
    }, [ mapRef, layers, mapLoaded, styleLoaded ])
    
    useEffect(() => {
        if (mapLoaded && styleLoaded) {
            clearLayers(selectedCategories)

            if (selectedCategories.length > 0) {
                for (let cat of selectedCategories) {
                    if (layers.includes(cat)) {
                        mapRef.current.setLayoutProperty(cat, 'visibility', 'visible')
                    }
                }
            }
        }
    }, [ mapRef, layers, clearLayers, selectedCategories, mapLoaded, styleLoaded ])

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: process.env.REACT_APP_MAPBOX_STYLE,
            center: [ process.env.REACT_APP_MAPBOX_CENTER_LON, process.env.REACT_APP_MAPBOX_CENTER_LAT ],
            zoom: process.env.REACT_APP_MAPBOX_ZOOM,
            pitch: process.env.REACT_APP_MAPBOX_PITCH,
        })

        mapRef.current.scrollZoom.disable()

        if (isTouch) {
            mapRef.current.dragPan.disable()
        }

        mapRef.current.on('touchstart', function (e) {
            if (e.originalEvent.touches && e.originalEvent.touches.length > 1) {
                e.originalEvent.stopImmediatePropagation()

                mapRef.current.dragPan.enable()
            }
            else {
                mapRef.current.dragPan.disable()
            }
        })

        mapRef.current.once('load', () => {
            setMapLoaded(true)
        })

        mapRef.current.once('styledata', () => {
            setStyleLoaded(true)
        })

        return () => {
            mapRef.current.remove()
        }
    }, [ mapContainerRef, isTouch, setMapLoaded, setStyleLoaded ])

    useEffect(() => {
        if (mapLoaded && styleLoaded && data) {
            for (let category of data.poiCategoryCollection.items) {
                mapRef.current.loadImage(
                    category.icon.url,
                    (error, image) => {
                        if (error) throw error
                        
                        const imageName = `${category.sys.id}-icon`
    
                        mapRef.current.addImage(imageName, image)

                        const features = category.linkedFrom.poiCollection.items.map(poi => {
                            return {
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [ poi.location.lon, poi.location.lat ],
                                },
                                properties: {
                                    title: poi.name
                                },
                            }
                        })

                        mapRef.current.addSource(category.name, {
                            type: 'geojson',
                            data: {
                                type: 'FeatureCollection',
                                features,
                            },
                        })
    
                        mapRef.current.addLayer({
                            id: category.name,
                            type: 'symbol',
                            source: category.name,
                            layout: {
                                'icon-image': imageName,
                                'icon-size': 0.5,
                                visibility: 'none',
                            },
                        })

                        mapRef.current.on('mouseenter', category.name, function (e) {
                            // Change the cursor style as a UI indicator.
                            mapRef.current.getCanvas().style.cursor = 'pointer'
                            
                            const coordinates = e.features[0].geometry.coordinates.slice()
                            const title = e.features[0].properties.title
                            
                            // Ensure that if the map is zoomed out such that multiple
                            // copies of the feature are visible, the popup appears
                            // over the copy being pointed to.
                            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
                            }
                            
                            // Populate the popup and set its coordinates
                            // based on the feature found.
                            popup
                                .setLngLat(coordinates)
                                .setHTML(`<div><b>${title}</b></div>`)
                                .addTo(mapRef.current)
                        })
            
                        mapRef.current.on('mouseleave', category.name, function() {
                            mapRef.current.getCanvas().style.cursor = ''

                            popup.remove()
                        })

                        setLayers(previous => [
                            ...previous,
                            category.name,
                        ])
                    }
                )
            }
        }
    }, [ mapLoaded, styleLoaded, data, setLayers ])
    
    return (
        <div ref={mapContainerRef}></div>
    )
}