import { TOGGLE_MENU, CLOSE_MENU } from "../actions"

const initialState = {
    menuOpen: false,
}

export default (state = initialState, action) => {
    if (action.type === TOGGLE_MENU) {
        state = {
            ...state,
            menuOpen: !state.menuOpen,
        }
    }
    else if (action.type === CLOSE_MENU) {
        state = {
            ...state,
            menuOpen: false,
        }
    }

    return state
}