import React, { useState, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery';
import Plx from 'react-plx';
import "./neighborhood.scss"
import "../../styles/layout/section.scss"
import { HeaderText, ImageWithDescription, ImageOnly } from "../sections/Sections"
import { useQuery, gql } from '@apollo/client'
import Map from "./Map"
import { Waypoint } from 'react-waypoint';
import { Col } from "antd"
import { useMediaQuery } from 'react-responsive'
import Footer from '../../components/Footer'
let page_width = $(window).width()
let page_height = $(window).height()
let is_phone = (page_width >= 320 & page_width < 415) ? true : false

const GET_POI_DATA = gql`
    query getPoiData {
        poiCategoryCollection(order: [weight_DESC]) {
            items {
                sys {
                    id
                }
                name
                icon {
                    url
                }
                linkedFrom {
                    poiCollection {
                        items {
                            name
                            location {
                                lat
                                lon
                            }
                        }
                    }
                }
            }
        }
    }
`

const scrollEvent = (e) => {
    var frameNumber = Math.floor(window.pageYOffset)
    frameNumber < 10 &&
        $(".animated-route").css({ "transform": "" })
}

const onEnterSectionTwo = (val) => {
    // val.currentPosition === "inside" &&
    //     $(".neighborhood_page .nb_section_three_container").css({ "height": `${$(".nb_section_three_container").height() + page_height}` })
}

const onLeftSectionTwo = (val) => {
    val.currentPosition === "above" &&
        $(".neighborhood_page .first_image_right #image_only ").css({ "position": "sticky", "top": `${is_phone ? "5.5em" : "6rem"}` })
}

const Neighborhood = (props) => {
    const [ isMounted, setIsMounted ] = useState(false)
    const [ selectedCategories, setSelectedCategories ] = useState([])

    const { data: poiData } = useQuery(GET_POI_DATA)

    const categories = useMemo(() => {
        if (poiData) {
            return poiData.poiCategoryCollection.items.map(cat => cat.name)
        }

        return []
    }, [ poiData ])
    
    const isMobile = useMediaQuery({ query: '(max-width: 1199px)' })

    useEffect(() => {
        if (categories.length > 0) {
            setSelectedCategories(categories)
        }
    }, [ categories, setSelectedCategories ])
    
    useEffect(() => {
        setIsMounted(true)
        $(".animated-route").css({ "transform": "" })
        $(".header").removeClass("home-nav")
        window.addEventListener('scroll', scrollEvent)
        
        return () => {
            setIsMounted(false)
            window.removeEventListener('scroll', scrollEvent)
        }
    }, [ setIsMounted ])

    const toggleFilter = useCallback(cat => {
        setSelectedCategories(previous => {
            if (previous.includes(cat)) {
                return previous.filter(p => p != cat)
            }

            return [
                ...previous,
                cat,
            ]
        })
    }, [ setSelectedCategories ])

    const { page } = props

    return (
        <React.Fragment >
            <div className="neighborhood_page">
                <div className="section_one_container">
                    <Plx
                        disabled={isMounted ? isMobile : true}
                        className="section_one_plx"
                        parallaxData={[{
                            start: 0,
                            duration: 300,
                            properties: [
                                {
                                    startValue: 0,
                                    endValue: is_phone ? 77 : 85,
                                    property: "translateY"
                                },]
                        }]}>
                        <div className="image_container">
                            <div className="image" style={{
                                content: 'url("/images/Hillrose_section_one.jpg")',
                            }} />
                        </div>
                        <div className="mobile-only">
                            <HeaderText section={page.section_two} />
                        </div>
                    </ Plx>
                </div>

                <div className="neighborhood_content" >
                    <ConditionalWaypoint
                        disabled={isMobile}
                        topOffset={$(".inner").outerHeight()}
                        onEnter={(val) => onEnterSectionTwo(val)}
                        onLeave={(val) => onLeftSectionTwo(val)}
                    >
                        <div className="nb_section_two">
                            <HeaderText section={page.section_two} />
                        </div>
                    </ConditionalWaypoint>

                    <div className="nb_section_three_container">
                        <div className="col">
                            <div className="image">
                                <img src={page.left_image_one.image} />
                            </div>
                            
                            <div className="image">
                                <img src="/images/Col_Left_Two.jpg" />
                            </div>
                            
                            <div className="image">
                                <img src="/images/Col_Left_Three.jpg" />
                            </div>

                            {/* <p>{page.section_last.paragraph}</p> */}
                        </div>

                        <div className="col">
                            <div className="image">
                                <img src={page.right_image_one.image} />
                            </div>
                            
                            <div className="image">
                                <img src={page.right_image_two.image} />
                            </div>

                            <div className="image">
                                <img src={page.right_image_three.image} />
                            </div>
                            
                            <div className="image">
                                <img src="/images/Col_Right_Four.jpg" />
                            </div>

                            <div className="image">                            
                                <img src="/images/Col_Right_Five.jpg" />
                            </div>

                            <div className="image">                            
                                <img src="/images/Last_Section.jpg" />
                            </div>
                        </div>

                        {/* <ConditionalWaypoint
                            disabled={isMobile}
                            topOffset={$(".inner").outerHeight()}
                            onLeave={(val) => leftImage(val, ".nb_filter_container")}
                        >
                            <div className="last_images">
                                <ImageWithDescription section={page.section_last} />
                            </div>
                        </ConditionalWaypoint> */}
                    </div>

                    {/* <div className="nb_filter_container">
                        {categories.map(cat =>
                            <div
                                className={`filter_name ${cat} ${selectedCategories.includes(cat) ? 'filter_selected' : ''}`}
                                key={cat}
                                onClick={() => toggleFilter(cat)}
                            >
                                {cat}
                            </div>
                        )}
                    </div>

                    <Plx
                        id="nb_map"
                        disabled={isMobile}
                        parallaxData={[{
                            start: 3000,
                            end: 3500,
                            properties: [
                                {
                                    startValue: 0,
                                    endValue: 1,
                                    property: "opacity"
                                },]
                        }]}>
                        <div className="nb_map_container">
                            <Map
                                data={poiData}
                                selectedCategories={selectedCategories}
                            />
                        </div>
                    </ Plx> */}
                    
                    <Footer />
                </div>
                
            </div>
        </React.Fragment >
    )
}

const ConditionalWaypoint = ({ children, disabled = false, ...props }) => {
    if (disabled) {
        return children
    }

    return <Waypoint {...props}>{children}</Waypoint>
}

export default Neighborhood
