import { all, takeLatest } from "redux-saga/effects"

import {
    TEST_ACTION,
    GET_PRESS,
    FETCH_UNITS,
    RESET_CONTACT_FORM, GET_CONTACT_FORM
} from "../actions"
import { testSaga } from "./TestSaga"
import { fetchPressSaga } from "./PressSaga"
import { contactForm, contactFormReset } from "./EmailSaga"
import { fetchUnits } from "./fetchSaga"

export default function* () {
    yield all([
        takeLatest(TEST_ACTION, testSaga),
        takeLatest(GET_PRESS, fetchPressSaga),
        takeLatest(FETCH_UNITS, fetchUnits),
        // takeLatest(GET_REFRESH_TOKEN, loginSaga)
        takeLatest(GET_CONTACT_FORM, contactForm),
        takeLatest(RESET_CONTACT_FORM, contactFormReset),
    ]
    )
}